import Constants from 'Constants'
import { EventBus } from 'EventBus'
import Common from '@/assets/js/common.js'

const ExpeditionsMixin = {
    methods: {
        getAllDemandesExpeditions: async function(date) {
            const url = Constants.EXPEDITIONS_ADRESSED + "?licence_key="+Constants.USER_LICENCE_KEY + "&date=" + date

			const result = await this.$request.request_get_api("ExpeditionsMixin::getAddressedInvoicesToPay", url)
			.catch(error => {
				console.error("ExpeditionsMixin::getAddressedInvoicesToPay => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        setDemandesExpeditions: async function(resa_ids, params) {
			const url = this.constructRoute(Constants.EXPEDITIONS_ADRESSED_ID, {reservations_ids: resa_ids.join(',')})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("ExpeditionsMixin::setDemandesExpeditions", url, params, false)
			.catch(error => {
				console.error("ExpeditionsMixin::setDemandesExpeditions => ERROR", error)
			})

			return result ? result : null
        },

        createReservation: async function(contract_id, params) {
            const url = this.constructRoute(Constants.CONTRACT_RESERVATION_URL, {contract_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixin::addContract", url, params, false)
            .catch(error => {
                console.error("ContractMixin::addContract => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        }
    }
}

export default ExpeditionsMixin
