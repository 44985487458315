<template>
	<main>
		<HeaderTab :title="$t('global.demandes_expeditions')"  :return_action="'navigationBack'"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<e-datepicker
								v-model="date"
							/>

							<CustomTable
								ref="monte_resa"
								id_table="expeditions_clientarea"
								primaryKey="id"
								:items="items"
								:busy.sync="table_busy"
                				:externSlotColumns="['destinataire']"
							>
								<template v-slot:[`custom-slot-cell(destinataire)`]="{ data }">
									{{ data.destinataire.tiers_rs }}<br>
									{{ data.destinataire.tiers_address1 }}<br>
									{{ data.destinataire.tiers_postalcode }} {{ data.destinataire.tiers_town }}<br>
									<template v-if="data.destinataire.phones.length > 0">
										{{ data.destinataire.phones[0].phone_combine }}<br>
									</template>
									{{ data.destinataire.tiers_mail }}
								</template>
							</CustomTable>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
import { EventBus } from "EventBus";
import Expeditions from '@/mixins/Expeditions.js'
import Navigation from "@/mixins/Navigation.js"

export default {
	name: "Expeditions",
	mixins: [Expeditions, Navigation],
	data () {
		return {
			date: new Date(),
			items: null,
			table_busy: true,
			events_tab: {
				'TableAction::goToAckExpedition': this.ackExpedition,
				'TableAction::goToConfirmOpen': this.confirmOpen,
			}
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.table_busy = true
			this.getAllDemandesExpeditions(this.date.toDateInputValue()).then(expeditions => {
				this.items = expeditions
				this.table_busy = false
			})
		},

		async ackExpedition(reservations) {
			const ids = reservations.map(resa => resa.id)
			await this.setDemandesExpeditions(ids, {expedition_date: new Date()})
        	this.$refs.monte_resa.unselectAll()
        	EventBus.$emit('TableAction::stopSpin')
			this.init_component()
		},

		async confirmOpen(reservations) {
			const ids = reservations.map(resa => resa.id)
			await this.setDemandesExpeditions(ids, {supported_date: new Date(), processed: true})
        	this.$refs.monte_resa.unselectAll()
        	EventBus.$emit('TableAction::stopSpin')
			this.init_component()
		},
	},
	watch: {
		date () {
			this.init_component()
		}
	},
	components: {
		HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
	}
}
</script>
